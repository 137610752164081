import React from "react";
import { Link } from "react-router-dom";

import "../../components/stylesheets/home.css";

import homeSec1Img from "../../assets/media/Her-new-correct-222.svg";
import WtrMgmtSys from "../../assets/media/Water_Management-1.svg";
import IndMontSol from "../../assets/media/IM-1.svg";
import SusDevlGol from "../../assets/media/SD.svg";
import Commercial from "../../assets/media/commercials.svg";
import Residential from "../../assets/media/Residentials.svg";
import Mission from "../../assets/media/Mission.svg";
import TankMont from "../../assets/media/Asset1.png";
import MotorCont from "../../assets/media/Asset2.png";
import MgmtGlob from "../../assets/media/Asset3.png";

function Home() {
  return (
    <div>
      <section className="homeSec1">
        <div className="homeSec1Left">
          <h2>Stop needless waste of water, electricity and time.</h2>
          <p>
            Save time and money managing water, motors, and tanks, so you can
            live a hassle-free life.
          </p>
          <button>Book A Site Visit</button>
        </div>
        <div className="homeSec1Right">
          <img src={homeSec1Img} alt="homeSec1Img" />
        </div>
      </section>
      <section className="homeSec2">
        <h3>
          <b>Three Segments</b>
        </h3>
        <div className="homeSec2Tiles">
          <div className="homeSec2Tile1 tile">
            <img src={WtrMgmtSys} alt="Water management Systems" />
            <h4>Water management Systems</h4>
            <p>
              End to end water management with dedicated hardware & software.
            </p>
          </div>
          <div className="homeSec2Tile2 tile">
            <img src={IndMontSol} alt="Industrial Monitoring Solutions" />
            <h4>Industrial Monitoring Solutions</h4>
            <p>
              Remote & onsite monitoring systems with proven ROI models &
              profitability.
            </p>
          </div>
          <div className="homeSec2Tile3 tile">
            <img src={SusDevlGol} alt="Sustainable Development Goals" />
            <h4>Sustainable Development Goals</h4>
            <p>
              Focus on sustainability for achieving smart cities & CSR goals.
            </p>
          </div>
        </div>
      </section>
      <section className="homeSec3">
        <h3>Two Markets</h3>
        <div className="homeSec3Tiles">
          <div className="homeSec3Tile1 tile">
            <img src={Residential} alt="Residential" />
            <h4>Residential</h4>
            <p>
              Solutions designed according to the water supply conditions in
              your area.
            </p>
            <Link to="Residential">
              <button>Learn More</button>
            </Link>
          </div>
          <div className="homeSec3Tile2 tile">
            <img src={Commercial} alt="Commercial" />
            <h4>Commercial</h4>
            <p>
              Integrated water management systems and Industrial Monitoring
              Solutions.
            </p>
            <Link to="Commercial">
              <button>Learn More</button>
            </Link>
          </div>
        </div>
      </section>
      <section className="homeSec4">
        <h3>Our Mission</h3>
        <img src={Mission} alt="Our Mission" />
        <p>Accelerate the world's transition to a sustainable lifestyle.</p>
      </section>
      <section className="homeSec5">
        <h3>Three steps to get hassle-free water management</h3>
        <div className="homeSec5Div1">
          <div className="homeSec5Tile1">
            <img alt="Step01" />
            <p>
              <b>Book a visit</b>
            </p>
            <p>
              Book a site visit, so that our team inspects your site for product
              installation.
            </p>
          </div>
          <div className="homeSec5Tile2">
            <img alt="Step02" />
            <p>
              <b>Site Inspection</b>
            </p>
            <p>
              Your site is inspected & our team recommends the best available
              solution for your site.
            </p>
          </div>
          <div className="homeSec5Tile3">
            <img alt="Step03" />
            <p>
              <b>Product Installation</b>
            </p>
            <p>
              After the proposal, you decide when you want your water management
              system to be installed.
            </p>
          </div>
        </div>
        <div className="homeSec5Div2">
          <h2>
            <b>Our Solution Start from</b>
          </h2>
          <p>
            <b>
              Rs. 12,500 (Inclusive of Consultancy, Installation and After sales
              services)
            </b>
          </p>
          <p>* With 6 Months Warranty</p>
          <button>Book A Site Visit</button>
        </div>
      </section>
      {/* Home Section#6 */}
      <section className="homeSec6">
        <h3>Simplest way to monitor your tanks.</h3>
        <div>
          <img src={TankMont} alt="Tank Monitoring" />
        </div>
        <div>
          <h6>Smart Level Indicator</h6>
          <div>
            <div>
              <img alt="Level Check" />
              <p>Level Check</p>
            </div>
            <div>
              <img alt="Smart App Monitoring" />
              <p>Smart App Monitoring</p>
            </div>
            <div>
              <img alt="Level Alerts" />
              <p>Level Alerts</p>
            </div>
            <div>
              <img alt="Usage Optimization" />
              <p>Usage Optimization</p>
            </div>
          </div>
          <button>Book a site visit</button>
        </div>
      </section>
      <section className="homeSec7">
        <h3>Simplest way to control your motors.</h3>
        <div>
          <img src={MotorCont} alt="Motor Control" />
        </div>
        <div>
          <h6>Automatic Motor Control</h6>
          <div>
            <div>
              <img alt="Flow Detection" />
              <p>Flow Detection</p>
            </div>
            <div>
              <img alt="Auto Motor Switching" />
              <p>Auto Motor Switching</p>
            </div>
            <div>
              <img alt="Level Detection" />
              <p>Level Detection</p>
            </div>
            <div>
              <img alt="Control Multiple Motors" />
              <p>Usage Multiple Motors</p>
            </div>
          </div>
          <button>Book a site visit</button>
        </div>
      </section>
      <section className="homeSec8">
        <h3>Manage your tanks from anywhere in the world.</h3>
        <div>
          <img src={MgmtGlob} alt="Manage Anywhere" />
        </div>
        <div>
          <h6>Smart Motor Control</h6>
          <div>
            <div>
              <img alt="Flow Detection" />
              <p>Flow Detection</p>
            </div>
            <div>
              <img alt="Level Detection" />
              <p>Level Detection</p>
            </div>
            <div>
              <img alt="Smart App" />
              <p>Smart App</p>
            </div>
            <div>
              <img alt="Usage Optimization" />
              <p>Usage Optimization</p>
            </div>
          </div>
          <button>Book a site visit</button>
        </div>
      </section>
      <section className="homeSec9">
        <h3>They followed the same steps</h3>
        <div></div>
      </section>
    </div>
  );
}

export default Home;
