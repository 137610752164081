import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/media/AsaniLogo.png";

function Header() {
  function menuFunc() {
    var x = document.querySelector(".headerIcon");
    x.classList.toggle("change");
    var headerRight = document.querySelector("#headerRight");
    headerRight.classList.toggle("responsiveMenu");
  }

  return (
    <header>
      <div id="headerLeft">
        <img src={Logo} alt="AsaniIo" id="logo" />
      </div>
      <div id="headerRight">
        <nav>
          <ul>
            <li className="navItem">
              <Link className="navLink" to="/">
                Home
              </Link>
            </li>
            <li className="navItem">
              <Link className="navLink" to="Residential">
                Residential
              </Link>
            </li>
            <li className="navItem">
              <Link className="navLink" to="Commercial">
                Commercial
              </Link>
            </li>
            <li className="navItem">
              <Link className="navLink" to="About">
                About us
              </Link>
            </li>
            <li className="navItem">
              <Link className="navLink" to="FAQs">
                FAQs
              </Link>
            </li>
            <li>
              <button>book a site visit</button>
            </li>
          </ul>
        </nav>
      </div>
      <div className="headerBtn" id="headerBtn">
        <div className="headerIcon" onClick={() => menuFunc(this)}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
    </header>
  );
}

export default Header;
