import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div id="footerLeft">
        <div>
          <div className="companyName">
            <h1>
              <b>asani.io</b>
            </h1>
            <span>asani.io &copy; All Rights Reserved</span>
          </div>
          <div className="privacyLinks">
            <Link className="privacyLink" to="/">
              Privacy Policy
            </Link>
            <Link className="privacyLink" to="/">
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
      <div id="footerRight">
        <div className="contactLinks">
          <button>contact us</button>
          <div className="socialLinks">
            <Link className="socialLink" to="/">
              Facebook
            </Link>
            <Link className="socialLink" to="/">
              Instagram
            </Link>
            <Link className="socialLink" to="/">
              LinkedIn
            </Link>
            <Link className="socialLink" to="/">
              YouTube
            </Link>
          </div>
        </div>
        <h2 className="sologan">
          <b>
            <span>innovation</span> meets comfort.
          </b>
        </h2>
      </div>
    </footer>
  );
}

export default Footer;
