import React from "react";
import Dummy from "../dummy";

function About() {
  return (
    <div>
      <h2>About</h2>
      <Dummy />
    </div>
  );
}

export default About;
