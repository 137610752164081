import React from "react";
import Dummy from "../dummy";

function Commercial() {
  return (
    <div>
      <h2>Commercial</h2>
      <Dummy />
    </div>
  );
}

export default Commercial;
