import React from "react";
import Dummy from "../dummy";

function Residential() {
  return (
    <div>
      <h2>Residential</h2>
      <Dummy />
    </div>
  );
}

export default Residential;
