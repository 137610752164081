import React from "react";
import Dummy from "../dummy";

function FAQs() {
  return (
    <div>
      <h2>FAQs</h2>
      <Dummy />
    </div>
  );
}

export default FAQs;
