import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/header";
import Footer from "./components/footer";

import Home from "./pages/Home";
import Residential from "./pages/Residential";
import Commercial from "./pages/Commercial";
import About from "./pages/About";
import FAQs from "./pages/FAQs";
// import Dummy from "./pages/dummy";

function App() {
  // document.addEventListener("mousemove", (e) => {
  //   var cursor = document.querySelector(".cursor");
  //   cursor.setAttribute("style", "display: block;");
  //   cursor.setAttribute(
  //     "style",
  //     "top: " + (e.pageY - 10) + "px; left: " + (e.pageX - 10) + "px;"
  //   );
  // });

  return (
    <Router>
      <div>
        <div className="cursor"></div>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="Residential" element={<Residential />} />
            <Route path="Commercial" element={<Commercial />} />
            <Route path="About" element={<About />} />
            <Route path="FAQs" element={<FAQs />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
